import React from 'react';
import { BrowserRouter, Link } from "react-router-dom";

class NavigationBar extends React.Component {
  render() {
    return (
      <div className='APP'>
        <ul class = "box1">
        <li><h4 class = "center">Vegan Gourmet</h4></li>
        <BrowserRouter>
        <li>
        <Link to="/">ホーム</Link>
       </li>
        </BrowserRouter>
      </ul>
      </div>
    );
  }
}

export default NavigationBar;