import React from 'react';

class PhoneHome extends React.Component {
  render() {
    return (
      <div className='APP'>
        <div className='APP'>
        <ul class="box2" align="left">
        
        <h3 class="h3font1" align="left">
        「ヴィーガン」とは、<br />
        <span class="span1">完全</span>菜食主義者の方を指し、<br />
        1944年イギリスのヴィーガン協会の設立<br />
        と共に生まれた言葉です。
        </h3>
        <h4 class="h4font2" align="left">
              食生活において肉や魚を食べず、<br />
              卵や乳製品といった動物由来の食材も口にしません。<br />
              料理用の油も植物性のものを使うといった、<br />
              徹底した菜食主義を実践。<br />
              また、菜食に加え、<span class="span1">動物由来の服や生活用品も一切使いません。</span>
        </h4>
      </ul>
      <ul class="whitebox">
        <h3 class="h3font2">
        </h3>
      </ul>

      <ul class="box3">
        <h3 class="h3font2" align="left">
        ◆ヴィーガン生活を始めたいが、どこの店に行っても動物由来のものばかり売っているのではないか<br />
        ◆ヴィーガン生活はしているが、行き先にはヴィーガン対応の店がないのではないか　<br />
        <span class="span2">等々と困っている方は少なからずいるはずです。</span>
        </h3>
        <h2 align="left">
          そこで、こちらから提供させていただくアプリをお使いになりませんか？
        </h2>
        <h3 align="left">
        こちらは地球に優しい野菜料理を宣伝、情報提供をサポートするアプリです。<br />
        「肉の消費を減らした野菜中心の食生活の方が良い」と考えているベジタリアンの方はもちろん、<br />
        ヴィーガンの方の食生活をサポートさせていただきます。
        </h3>
      </ul>
      </div>
      </div>
    );
  }
}

export default PhoneHome;