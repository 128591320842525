import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div className='footer'>
        <div className='footer-inner'>
          <ul className='footer-list'>
            <li align="left">お問い合わせ:　vegangourmetapp@gmail.com
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;