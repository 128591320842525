import './App.css';
import './index.css';
import React from 'react';
import img2 from './images/IMG_3917.JPG';
import img3 from './images/IMG_3916.JPG';
import img4 from './images/IMG_3914.JPG';
import img5 from './images/IMG_3915.JPG';
import img6 from './images/IMG_3913.JPG';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import Footer from './Footer';
import useMedia from "use-media";
import NavigationBar from './NavigationBar';
import PCHome from './PCHome';
import PhoneHome from './PhoneHome';
import PCPromotion from './PCPromotion';
import PhonePromotion from './PhonePromotion';

const images = [
    {
      original: img2,
      thumbnail: img2,
    },
    {
      original: img3,
      thumbnail: img3,
    },
    {
      original: img4,
      thumbnail: img4,
    },
    {
      original: img5,
      thumbnail: img5,
    },
    {
      original: img6,
      thumbnail: img6,
    },
  ];

const autoPlay = true;
const showIndex = true;

function App() {
  const isWide = useMedia({ minWidth: "1000px" });
  return (
    <div className="App">
      <NavigationBar />
      <h1>ヴィーガン　グルメ</h1>
      <h6>いつでも気軽にヴィーガン対応レストランの検索をサポート</h6>
      {isWide ? <PCHome /> : <PhoneHome />}
      
      <ul class="whitebox">
        <h3 class="h3font2">
        </h3>
      </ul>
      <ul class="whitebox">
      <ImageGallery items={images} autoPlay={autoPlay} showIndex={showIndex}/>
      </ul>

      {isWide ? <PCPromotion /> : <PhonePromotion />}
      <Footer />
    </div>
  );
}

export default App;
