import React from 'react';
import appledl from './images/appledl.png';
import qrcode from './images/qrcode.png';

class PCPromotion extends React.Component {
  render() {
    return (
      <div className='APP'>
        <ul class="whitebox">
        <h3 class="h3font2">
        どこでもいつでも気軽にアプリヴィーガンレストランを検索しましょう！
        </h3>
        <h4 class="h3font2">
        ※コミュニティ機能制作中、お楽しみください！
        </h4>
        <table align='center'>
          <tr>
            <a href="https://apps.apple.com/app/v-%E3%82%B0%E3%83%AB%E3%83%A1/id6443967798">
              <img src={appledl} alt=""/>
            </a>
          </tr>
          <tr>
          <img src={qrcode} alt=""/>
          </tr>
        </table>
        
      </ul>
      </div>
    );
  }
}

export default PCPromotion;